import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import '../css/project.css';
import TextBlock from "../flexible_fields/TextBlock";
import ClientsBlock from "../flexible_fields/ClientsBlock";
import FeaturedProjectsBlock from "../flexible_fields/FeaturedProjectsBlock";
import AllWorksBlock from "../flexible_fields/AllWorksBlock";
import SingleProjectImage from "../flexible_fields/SingleProjectImage";
import SmallSingleProjectImage from "../flexible_fields/SmallSingleProjectImage";
import ContactBlock from "../flexible_fields/ContactBlock";
import WhoWeAreBlock from "../flexible_fields/WhoWeAreBlock";
import WhatWeDo from "../flexible_fields/WhatWeDo";
import RelatedWorks from "../flexible_fields/RelatedWorks";
import CreditsBlock from "../flexible_fields/CreditsBlock";
import DoubleImageRightAndVideoBlock from "../flexible_fields/DoubleImageRightAndVideoBlock";
import DoubleImageLeftAndVideoBlock from "../flexible_fields/DoubleImageLeftAndVideoBlock";
import DoubleProjectVideo from "../flexible_fields/DoubleProjectVideo";
import DoubleProjectImage from "../flexible_fields/DoubleProjectImage";
import FullScreenVideo from "../flexible_fields/FullScreenVideo";
import SmallProjectVideo from "../flexible_fields/SmallProjectVideo";
import SingleProjectVideo from "../flexible_fields/SingleProjectVideo";
import FullScreenImage from "../flexible_fields/FullScreenImage";
import { gsap } from "gsap-trial";
import { Observer } from "gsap-trial/Observer";
import { gql, useQuery } from "@apollo/client";


gsap.registerPlugin(Observer);

const PROJECT_QUERY = gql`
query ProjectId($url: ID!) {
  project(id: $url, idType: URI) {
    id
    title
    slug
    projectId
    seo {
      fullHead
    }
    artists {
      edges {
        node {
          id
          name
        }
      }
    }
    clients {
      edges {
        node {
          name
        }
      }
    }
    years {
      edges {
        node {
          name
        }
      }
    }
    categories {
      edges {
        node {
          name
        }
      }
    }
    buildYourPage {
      addContentBlock {
        ... on Project_Buildyourpage_AddContentBlock_DoubleProjectImage {
          addText
          fieldGroupName
          leftImage {
            altText
            srcSet
            sourceUrl
          }
          rightImage {
            srcSet
            sourceUrl
            altText
          }
          optionalContent
          optionalHeading
        }
        ... on Project_Buildyourpage_AddContentBlock_SingleProjectImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            srcSet
            sourceUrl
            title
            uri
            slug
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_SmallSingleProjectImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            uri
            title
            status
            srcSet
            sourceUrl
            slug
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_ProjectInfo {
          fieldGroupName
        }
        ... on Project_Buildyourpage_AddContentBlock_RelatedWorks {
          fieldGroupName
        }
        ... on Project_Buildyourpage_AddContentBlock_CreditsBlock {
          fieldGroupName
          addTextBlocks {
            fieldGroupName
            heading
            text
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_TextBlock {
          content
          fieldGroupName
          heading
        }
        ... on Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          rightImage {
            altText
            uri
            title
            srcSet
            sourceUrl
          }
          videoLeft {
            title
            mediaItemUrl
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          leftImage {
            altText
            srcSet
            sourceUrl
            title
            uri
          }
          videoRight {
            title
            mediaItemUrl
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_FullScreenVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_SmallProjectVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_FullScreenImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            uri
            title
            status
            srcSet
            sourceUrl
            slug
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_SingleProjectVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_DoubleProjectVideo {
          fieldGroupName
          videoLeft {
            mediaItemUrl
            title
          }
          optionalContent
          optionalHeading
          videoRight {
            mediaItemUrl
            title
          }
        }
      }
    }
  }
}
`;

export default function Project(props) {

  let { projuri } = useParams();
  const { loading, error, data } = useQuery(PROJECT_QUERY, {
    variables: {
      url: projuri
  }
  });
  //const [newData, updatedData] = useState(0);
  
 
  useEffect(() => {
    if(data) {
     
      const tlArrow = gsap.timeline( {repeat:-1, repeatDelay: 2} );
      tlArrow.fromTo("#downArrow", 
      {
        opacity: 0.1,
      },
      {
        opacity: 1,
        duration: 2,
        delay: 5,
        ease: "Bounce.easeIn",
        loop: -1,
      });

    const colorArray = ["transparent"];
const slides = document.querySelectorAll("section");
const container = document.querySelector("#panelWrap");
let dots = document.querySelector(".dots");
let toolTips = document.querySelectorAll(".toolTip");
let oldSlide = 0;
let activeSlide = 0;
let navDots = [];
let dur = 0.6;
let offsets = [];
let toolTipAnims = [];
let ih = window.innerHeight - 30;
document.querySelector("#upArrow").addEventListener("click", slideAnim);
document.querySelector("#downArrow").addEventListener("click", slideAnim);
let down =document.querySelector("#downArrow");
let up =document.querySelector("#upArrow");

// create nev dots and add tooltip listeners
for (let i = 0; i < slides.length; i++) {
let tl = gsap.timeline({paused:true, reversed:true});
  gsap.set(slides[i], { backgroundColor: colorArray[i] });
  let newDot = document.createElement("div");
  newDot.className = "dot";
  newDot.index = i;
  navDots.push(newDot);
  newDot.addEventListener("click", slideAnim);
  newDot.addEventListener("mouseenter", dotHover);
  newDot.addEventListener("mouseleave", dotHover);
  dots.appendChild(newDot);
  offsets.push(-slides[i].offsetTop);
  tl.to(toolTips[i], 0.25, {opacity:1, ease:"linear.easeNone"});
  toolTipAnims.push(tl);
}

// get elements positioned
gsap.set(".dots", {yPercent:-50});
gsap.set(".toolTips", {yPercent:-50});
  
// side screen animation with nav dots
const dotAnim = gsap.timeline({paused:true});
dotAnim.to(
  ".dot",
  {
    stagger: { each: 1, yoyo: true, repeat: 1 },
    borderBottom: "1px solid #FFF",
    rotation: 0.1,
    ease: "none"
  },
  0.5
);
dotAnim.time(1);

// tooltips hovers
function dotHover() {
  toolTipAnims[this.index].reversed() ? toolTipAnims[this.index].play() : toolTipAnims[this.index].reverse();
}

// figure out which of the 4 nav controls called the function
  function slideAnim(e) {
    
  oldSlide = activeSlide;
  // dragging the panels
  if (this.id === "dragger") {
    activeSlide = offsets.indexOf(this.endY);
  } else {
    if (gsap.isTweening(container)) {
      return;
    }
    // up/down arrow clicks
    if (this.id === "downArrow" || this.id === "upArrow") {
      activeSlide = this.id === "downArrow" ? (activeSlide += 1) : (activeSlide -= 1);
      // click on a dot
    } else if (this.className === "dot") {
      activeSlide = this.index;
      // scrollwheel
    } else {
      activeSlide = e.deltaY > 0 ? (activeSlide += 1) : (activeSlide -= 1);
    }
  }
  // make sure we're not past the end or beginning slide
  activeSlide = activeSlide < 0 ? 0 : activeSlide;
  activeSlide = activeSlide > slides.length - 1 ? slides.length - 1 : activeSlide;
  if (oldSlide === activeSlide) {
    return;
  }
  // if we're dragging we don't animate the container
  if (this.id != "dragger") {
    gsap.to(container, dur, { y: offsets[activeSlide], ease: "power1.inOut", onUpdate:tweenDot });
  }
}

async function simulateMouseClick(el) {
  let opts = {view: window, bubbles: true, cancelable: true, buttons: 1};
  el.dispatchEvent(new MouseEvent("mousedown", opts));
  await new Promise(r => setTimeout(r, 50));
  el.dispatchEvent(new MouseEvent("mouseup", opts));
  el.dispatchEvent(new MouseEvent("click", opts));
}


const debounce = function(fn, d) {
  let timer;
  return function(e) {
   
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(e);
    }, d);
  }
}

function slideAnimWheel(e) {
  if(e.deltaY > 0) {
    simulateMouseClick(down);
  } else {
    simulateMouseClick(up);
  }
}

const scrollSection = (e) => {
slideAnimWheel(e);
}


gsap.set(".hideMe", {opacity:1});
window.addEventListener("wheel", debounce(scrollSection, 40));
window.addEventListener("resize", newSize);




newSize();

// resize all panels and refigure draggable snap array
function newSize() {
  offsets = [];
  ih = window.innerHeight;
  gsap.set("#panelWrap", { height: slides.length * ih });
  gsap.set(slides, { height: ih });
  for (let i = 0; i < slides.length; i++) {
    offsets.push(-slides[i].offsetTop);
  }
  gsap.set(container, { y: offsets[activeSlide] });
}

// tween the dot animation as the draggable moves
  function tweenDot() {
    gsap.set(dotAnim, {
    time: Math.abs(gsap.getProperty(container, "y") / ih) + 1
  });
}

    }


    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          gsap.fromTo(entry.target.querySelectorAll('.imgreveal'),
            {
              x: 0,
              scale: 1.4,
            },
            {
            x: "-200%",
            ease: "power1.in",
            scale: 1,
            duration: 2,
          });
          observer.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.imgVid:nth-child(even)').forEach(img => { observer.observe(img) });
    }
    
    else;

    if(!!window.IntersectionObserver){
      let observerOdd = new IntersectionObserver((entries, observerOdd) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          gsap.fromTo(entry.target.querySelectorAll('.imgreveal'),
            {
              x: 0,
              scale: 1.4,
            },
            {
            x: "200%",
            ease: "power1.in",
            scale: 1,
            duration: 2,
          });
          observerOdd.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.imgVid:nth-child(odd)').forEach(img => { observerOdd.observe(img) });
    }
    
    else;

    if(!!window.IntersectionObserver){
      let observerTwo = new IntersectionObserver((entries, observerTwo) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          console.log(entry.target.querySelector('hr'));
          gsap.to(entry.target.querySelectorAll('hr'),
            {
            x: "101%",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('h3'),
            {
            y: "25px",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('p'),
            {
            y: "-200px",
            ease: "power1.in",
            duration: 1,
          });
          observerTwo.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.hideShow').forEach(img => { observerTwo.observe(img) });
    }
    
    else;

    if(!!window.IntersectionObserver){
      let observerThree = new IntersectionObserver((entries, observerThree) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          console.log(entry.target.querySelector('hr'));
          gsap.to(entry.target.querySelectorAll('hr'),
            {
            x: "101%",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('h2'),
            {
            y: "25px",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('.creditItems'),
            {
            y: "401px",
            ease: "power1.in",
            duration: 1,
          })
          ;
          observerThree.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.hideShowCredits').forEach(img => { observerThree.observe(img) });
    }
    
    else;


  });
  

 
  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  if (error) return <p>Error :( </p>;
 
  
  
if(data) {
  const helmetContext = {};
  const fullHead = parse(data.project.seo.fullHead);
  return (
<>
<HelmetProvider context={helmetContext}>
      <Helmet>
      <body id="projectPage"></body>
      {fullHead}
      </Helmet>
      <div className="hideMe">

      <h1 className="projectTit small">{data.project.title}</h1>
      
      <svg id="downArrow" className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.5 34.26">
        <g >
          <path id="Pointer" d="m.25,19.25l25,14.43,25-14.43"/>
          <path id="Down" d="m25.25,33.68V0"/>
        </g>
      </svg>
      <svg id="upArrow" className="arrow" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 100 100">
        
      </svg>

        <div id="masterWrap">
        <div id="panelWrap">
    
        
  
      {data.project.buildYourPage.addContentBlock
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
      const randomString = (length) => [ ...Array(length) ].map(() => (~~(Math.random() * 36)).toString(36)).join('');


      for (const key in obj) {          
       

      if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SingleProjectImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SingleProjectImage imgVid">
            <SingleProjectImage key={randomString(14)}  obj={obj} />
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SmallSingleProjectImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SmallSingleProjectImage imgVid">
            <SmallSingleProjectImage key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_ProjectInfo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL ProjectInfo">
          <div className={obj.fieldGroupName}>
          <div className="hideShow">
          <h3 className="small">Project Name</h3>
          <hr className="hideShowHR" />
          <p>{data.project.title}</p>
          </div>
          <div className="hideShow">
          <h3 className="small">Artist</h3>
          <hr className="hideShowHR" />
          <p>{data.project.artists.edges[0].node?.name}</p>
          </div>
          <div className="hideShow">
          <h3 className="small">Year</h3>
          <hr className="hideShowHR" />
          <p>{data.project.years.edges[0].node?.name}</p>
          </div>
          <div className="hideShow">
          <h3 className="small">Client</h3>
          <hr className="hideShowHR" />
          <p>{data.project.clients.edges[0].node?.name}</p>
          </div>
          </div>
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_RelatedWorks') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL RelatedWorks">
          <div className={obj.fieldGroupName}>
            <div className="relatedTitle">
              <h3 className="large">More</h3>
            </div>

            <div className="sliderSection">
            <RelatedWorks key={randomString(14)}  obj={data.project.artists.edges[0].node?.name} />

            </div>

          </div>
        </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_CreditsBlock') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL CreditsBlock">
            <CreditsBlock key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_TextBlock') {
        return (
            <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL TextBlock">
            <TextBlock key={randomString(14)}  obj={obj} />
          
              </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleImageRightAndVideoBlock imgVid">
            <DoubleImageRightAndVideoBlock key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleImageLeftAndVideoBlock imgVid">
            <DoubleImageLeftAndVideoBlock key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleProjectVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleProjectVideo imgVid">
            <DoubleProjectVideo key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleProjectImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleProjectImage imgVid">
            <DoubleProjectImage key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_FullScreenVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL FullScreenVideo imgVid">
            <FullScreenVideo key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SmallProjectVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SmallProjectVideo imgVid">
            <SmallProjectVideo key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SingleProjectVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SingleProjectVideo imgVid">
            <SingleProjectVideo key={randomString(14)}  obj={obj} />
          
          </section>
        )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_FullScreenImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL FullScreenImage imgVid">
            <FullScreenImage key={randomString(14)}  obj={obj} />
          
          </section>
        )
      }  else {
        return (null);
      }
    }
  
  }
  )}
    </div>
        </div>

      <div className="dots">
      </div>

      <div className="toolTips">
      {data.project.buildYourPage.addContentBlock
          .filter(({ __typename }) => __typename)
          .map((flexFields, key) => {
          const obj = flexFields;
          for (const key in obj) {
            
            if (obj.hasOwnProperty(key)) {
              if(key === '__typename') {
                return (
                  <div className="toolTip">
                 
                </div>
                )
              }
            }
            
          }
          
        
        }
        
      )}
      </div>

    </div>
    </HelmetProvider>
    </>
  );
  }
}