import React from 'react';
import { ApolloProvider } from '@apollo/client';
import {  Route, Routes } from 'react-router-dom';
import './App.scss';
import HomePage from "./components/HomePage";
import Sitemap from "./components/sitemap";
import ContactPage from "./components/Contact";
import AllClients from "./components/AllClients";
import ClientsArchive from "./components/ClientsPage";
import ArtistsArchive from "./components/ArtistsPage";
import WorksPage from "./components/WorksPage";
import WhatWeDo from "./components/WhatWeDo";
import AllWorksPage from "./components/AllWorksPage";
import AllClientsPage from "./components/AllClientsPage";
import Pages from "./components/Pages";
import ProjectPage from "./components/ProjectPage";
import client from "./lib/apollo";
import Layout from "./layouts/layout";


export default function App() {


  return (
    <>
              <div className="custom-cursor"></div>

  <ApolloProvider client={client}>
    <Layout>
    <Routes>
      <Route exact path="/" element={<HomePage />} />
      <Route exact path="/sitemap" element={<Sitemap />} />
      <Route exact path="/contact" element={<ContactPage />} />
      <Route exact path="/our-clients" element={<AllClients />} />
      <Route path="/client/:clienturi" element={<AllClientsPage />} />
      <Route path="/artists/:artisturi" element={<ArtistsArchive />} />
      <Route exact path="/about" element={<WhatWeDo />} />
      <Route exact path="/works" element={<AllWorksPage />} />
      <Route exact path="/works/all-work" element={<AllWorksPage />} />
      <Route path="/:myuri" element={<Pages />} />
      <Route path="/projects/:projuri" element={<ProjectPage />} />
    </Routes>
    </Layout>
  </ApolloProvider>
  </>
  );
  
}