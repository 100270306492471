import React, {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';
import '../css/project.css';
import TextBlock from "../flexible_fields/TextBlock";
import ClientsBlock from "../flexible_fields/ClientsBlock";
import FeaturedProjectsBlock from "../flexible_fields/FeaturedProjectsBlock";
import AllWorksBlock from "../flexible_fields/AllWorksBlock";
import SingleProjectImage from "../flexible_fields/SingleProjectImage";
import SmallSingleProjectImage from "../flexible_fields/SmallSingleProjectImage";
import ContactBlock from "../flexible_fields/ContactBlock";
import WhoWeAreBlock from "../flexible_fields/WhoWeAreBlock";
import WhatWeDo from "../flexible_fields/WhatWeDo";
import RelatedWorks from "../flexible_fields/RelatedWorks";
import CreditsBlock from "../flexible_fields/CreditsBlock";
import DoubleImageRightAndVideoBlock from "../flexible_fields/DoubleImageRightAndVideoBlock";
import DoubleImageLeftAndVideoBlock from "../flexible_fields/DoubleImageLeftAndVideoBlock";
import DoubleProjectVideo from "../flexible_fields/DoubleProjectVideo";
import DoubleProjectImage from "../flexible_fields/DoubleProjectImage";
import FullScreenVideo from "../flexible_fields/FullScreenVideo";
import SmallProjectVideo from "../flexible_fields/SmallProjectVideo";
import SingleProjectVideo from "../flexible_fields/SingleProjectVideo";
import FullScreenImage from "../flexible_fields/FullScreenImage";
import { gsap } from "gsap-trial";
import { Observer } from "gsap-trial/Observer";
import { gql, useQuery } from "@apollo/client";


gsap.registerPlugin(Observer);

const PROJECT_QUERY = gql`
query ProjectId($url: ID!) {
  project(id: $url, idType: URI) {
    id
    title
    slug
    projectId
    seo {
      fullHead
    }
    artists {
      edges {
        node {
          id
          name
        }
      }
    }
    clients {
      edges {
        node {
          name
        }
      }
    }
    years {
      edges {
        node {
          name
        }
      }
    }
    categories {
      edges {
        node {
          name
        }
      }
    }
    buildYourPage {
      addContentBlock {
        ... on Project_Buildyourpage_AddContentBlock_DoubleProjectImage {
          addText
          fieldGroupName
          leftImage {
            altText
            srcSet
            sourceUrl
          }
          rightImage {
            srcSet
            sourceUrl
            altText
          }
          optionalContent
          optionalHeading
        }
        ... on Project_Buildyourpage_AddContentBlock_SingleProjectImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            srcSet
            sourceUrl
            title
            uri
            slug
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_SmallSingleProjectImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            uri
            title
            status
            srcSet
            sourceUrl
            slug
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_ProjectInfo {
          fieldGroupName
        }
        ... on Project_Buildyourpage_AddContentBlock_RelatedWorks {
          fieldGroupName
        }
        ... on Project_Buildyourpage_AddContentBlock_CreditsBlock {
          fieldGroupName
          addTextBlocks {
            fieldGroupName
            heading
            text
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_TextBlock {
          content
          fieldGroupName
          heading
        }
        ... on Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          rightImage {
            altText
            uri
            title
            srcSet
            sourceUrl
          }
          videoLeft {
            title
            mediaItemUrl
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          leftImage {
            altText
            srcSet
            sourceUrl
            title
            uri
          }
          videoRight {
            title
            mediaItemUrl
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_FullScreenVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_SmallProjectVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_FullScreenImage {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          addImage {
            altText
            uri
            title
            status
            srcSet
            sourceUrl
            slug
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_SingleProjectVideo {
          addText
          fieldGroupName
          optionalContent
          optionalHeading
          video {
            mediaItemUrl
            title
          }
        }
        ... on Project_Buildyourpage_AddContentBlock_DoubleProjectVideo {
          fieldGroupName
          videoLeft {
            mediaItemUrl
            title
          }
          optionalContent
          optionalHeading
          videoRight {
            mediaItemUrl
            title
          }
        }
      }
    }
  }
}
`;

export default function MobileProject(props) {

  let { projuri } = useParams();
  const { loading, error, data } = useQuery(PROJECT_QUERY, {
    variables: {
      url: projuri
  }
  });
  useEffect(() => {

  
 
    if(!!window.IntersectionObserver){
      let observer = new IntersectionObserver((entries, observer) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          gsap.fromTo(entry.target,
            {
              x: 0,
              scale: 1.4,
            },
            {
            x: "200%",
            ease: "power1.in",
            scale: 1,
            duration: 2,
          });
          observer.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.imgreveal').forEach(img => { observer.observe(img) });
    }
    
    else;

    if(!!window.IntersectionObserver){
      let observerTwo = new IntersectionObserver((entries, observerTwo) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          console.log(entry.target.querySelector('hr'));
          gsap.to(entry.target.querySelectorAll('hr'),
            {
            x: "101%",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('h3'),
            {
            y: "25px",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('p'),
            {
            y: "-200px",
            ease: "power1.in",
            duration: 1,
          });
          observerTwo.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.hideShow').forEach(img => { observerTwo.observe(img) });
    }
    
    else;

    
    if(!!window.IntersectionObserver){
      let observerThree = new IntersectionObserver((entries, observerThree) => { 
        entries.forEach(entry => {
        if(entry.isIntersecting){
          console.log(entry.target.querySelector('hr'));
          gsap.to(entry.target.querySelectorAll('hr'),
            {
            x: "101%",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('h2'),
            {
            y: "25px",
            ease: "power1.in",
            duration: 1,
          })
          gsap.to(entry.target.querySelectorAll('.creditItems'),
            {
            y: "401px",
            ease: "power1.in",
            duration: 1,
          })
          ;
          observerThree.unobserve(entry.target);
        }
        });
      }, {rootMargin: "0px 0px 0px 0px"});
      document.querySelectorAll('.hideShowCredits').forEach(img => { observerThree.observe(img) });
    }
    
    else;
});
  

 
  if(loading) {
    return (
        <div id="loading"></div>
    )
  }
  if (error) return <p>Error :( </p>;
 
  if(data) {

    

    const helmetContext = {};
  const fullHead = parse(data.project.seo.fullHead);
  return (
<>

      <HelmetProvider context={helmetContext}>
      <Helmet>
      <body id="projectPage"></body>
      {fullHead}
      </Helmet>
      <div className="hideMeTwo">

      <h1 className="projectTit small">{data.project.title}</h1>

        <div id="masterWrapTwo">
        <div id="panelWrapTwo">
    
        
  
      {data.project.buildYourPage.addContentBlock
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
      const randomString = (length) => [ ...Array(length) ].map(() => (~~(Math.random() * 36)).toString(36)).join('');

      for (const key in obj) {
        
      if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SingleProjectImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SingleProjectImage">
            <SingleProjectImage key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SmallSingleProjectImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SmallSingleProjectImage">
            <SmallSingleProjectImage key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_ProjectInfo') {
        return (
          <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL ProjectInfo">
          <div className={obj.fieldGroupName}>
          <div className="hideShow">
          <h3 className="small">Project Name</h3>
          <hr className="hideShowHR" />
          <p>{data.project.title}</p>
          </div>
          <div className="hideShow">
          <h3 className="small">Artist</h3>
          <hr className="hideShowHR" />
          <p>{data.project.artists.edges[0].node?.name}</p>
          </div>
          <div className="hideShow">
          <h3 className="small">Year</h3>
          <hr className="hideShowHR" />
          <p>{data.project.years.edges[0].node?.name}</p>
          </div>
          <div className="hideShow">
          <h3 className="small">Client</h3>
          <hr className="hideShowHR" />
          <p>{data.project.clients.edges[0].node?.name}</p>
          </div>
          </div>
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_RelatedWorks') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL RelatedWorks">
            <RelatedWorks key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_CreditsBlock') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL CreditsBlock">
            <CreditsBlock key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_TextBlock') {
        return (
            <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL TextBlock">
            <TextBlock key={randomString(14)}  obj={obj} />
          
              </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleImageRightAndVideoBlock') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleImageRightAndVideoBlock">
            <DoubleImageRightAndVideoBlock key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleImageLeftAndVideoBlock') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleImageLeftAndVideoBlock">
            <DoubleImageLeftAndVideoBlock key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleProjectVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleProjectVideo">
            <DoubleProjectVideo key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_DoubleProjectImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL DoubleProjectImage">
            <DoubleProjectImage key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_FullScreenVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL FullScreenVideo">
            <FullScreenVideo key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SmallProjectVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SmallProjectVideo">
            <SmallProjectVideo key={randomString(14)}  obj={obj} />
          
          </section>
          )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_SingleProjectVideo') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL SingleProjectVideo">
            <SingleProjectVideo key={randomString(14)}  obj={obj} />
          
          </section>
        )
      }
      else if (flexFields.__typename === 'Project_Buildyourpage_AddContentBlock_FullScreenImage') {
        return (
        <section key={randomString(14)} id={"section_" + randomString(14)} className="fullHeight paraL FullScreenImage">
            <FullScreenImage key={randomString(14)}  obj={obj} />
          
          </section>
        )
      }  else {
        return (null);
      }
    }
  }
  )}
    </div>
        </div>


    </div>
    </HelmetProvider>
    </>
  );
}
}