import React from "react";
import { useParams } from 'react-router-dom';

import { gql, useQuery } from "@apollo/client";


const PAGEQUERY = gql`
query PageId($url: ID!) {
    page(id: $url, idType: URI) {
      id
      title
    }
  }
`;

export default function Pages() {
    let { myuri } = useParams();
    const { loading, error, data } = useQuery(PAGEQUERY, {
        variables: {
            url: myuri
        }
    });
   
    if (loading) return <p>Loading posts…</p>;
    if (error) return <p>Error :( </p>;
  
  
    if(data.page !== null) {
      return (
        <div className="posts-list">
        {data.page.title}
      </div>
    
      );
    } else {

    return (
      <>
      <h1>404 PAGE</h1>
      </>
    );
}
}
