import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { gsap } from "gsap-trial";
import { Draggable } from "gsap-trial/Draggable";

gsap.registerPlugin(Draggable);


  const GET_FEATURED = gql`
  query getAllPosts {
    projects(where: {categoryName: "Featured"}) {
        edges {
          node {
            title
            slug
            featuredImage {
              node {
                altText
                srcSet
                title
              }
            }
          }
        }
      }
  }
`;



export default function FeaturedProjects(post) {

  const {
    obj
  } = post;

  const { loading, error, data } = useQuery(GET_FEATURED);
 
  useEffect(() => {
    if (data) {
    setTimeout(function () {

  let lastX = 0;
let direction;
let animDirection;
Draggable.create(document.createElement("div"), {
  trigger: "#container",
  type: "x",
  minimumMovement: 10,
  onDragStart: function() {
    if (inAnimation && inAnimation.isActive()) {
      // inAnimation.timeScale(10);
      // outAnimation.timeScale(10);
      gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
      
      if (this.getDirection() === "left") {
        nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
      } else {
        nextSlide = slides[currentSlide.index + 1] || slides[0];
      }
    } else if (this.getDirection() === "left") {
      setSlide(slides[currentSlide.index - 1] || slides[slides.length - 1]);
    } else {
      setSlide(slides[currentSlide.index + 1] || slides[0]);
    }
  }
});

let inAnimation = null;
let outAnimation = null;
let nextSlide = null;
let $container = document.getElementById("container");
let $containerWidth = $container.offsetWidth;
gsap.set("#container", { perspective: $containerWidth * 1.5 });
gsap.set(".slide", {
  backfaceVisibility: "hidden",
  transformOrigin: "50% 50% -" + $containerWidth / 2
});

window.addEventListener("resize", function() {
  let $containerWidth = $container.offsetWidth;

  gsap.set("#container", { perspective: $containerWidth * 1.5 });
  gsap.set(".slide", {
    transformOrigin: "50% 50% -" + $containerWidth / 2
  });
});

document.onkeydown = checkKey;

function checkKey(e) {

    e = e || window.event;

    if (e.keyCode == '38') {
        nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
    }
    else if (e.keyCode == '40') {
        nextSlide = slides[currentSlide.index + 1] || slides[0];
        if (inAnimation && inAnimation.isActive()) {
          gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
        } else {
          setSlide(nextSlide);
        }
    }
    else if (e.keyCode == '37') {
        nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
        if (inAnimation && inAnimation.isActive()) {
          gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
        } else {
          setSlide(nextSlide);
        }
    }
    else if (e.keyCode == '39') {
        nextSlide = slides[currentSlide.index + 1] || slides[0];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
    }

}


document.getElementById("prev").addEventListener("click", function() {
  nextSlide = slides[currentSlide.index - 1] || slides[slides.length - 1];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
});

document.getElementById("next").addEventListener("click", function() {
  nextSlide = slides[currentSlide.index + 1] || slides[0];
  if (inAnimation && inAnimation.isActive()) {
    gsap.to([inAnimation, outAnimation], 0.3, {timeScale: 10})
  } else {
    setSlide(nextSlide);
  }
});


let slideDelay = 1.5;
let slideDuration = 1;

let slideElements = document.querySelectorAll(".slide");
let slides = Array.prototype.map.call(slideElements, createSlide);

slides.forEach(function(slide, i) {
  slide.next = slides[i + 1] || slides[0];
  slide.duration = slideDuration;
});

let currentSlide = slides[0];

//let autoPlay = gsap.delayedCall(slideDelay, setSlide);

function setSlide(slide) {
  //autoPlay.restart(true);

  if (slide === currentSlide) {
    return;
  }

  if (currentSlide.index === 0 && slide.index === slides.length - 1) {
    currentSlide.setPrevInactive();
    currentSlide = slide;
    currentSlide.setPrevActive();
  } else if (currentSlide.index === slides.length - 1 && slide.index === 0) {
    currentSlide.setInactive();
    currentSlide = slide;
    currentSlide.setActive();
  } else if (slide.index < currentSlide.index) {
    currentSlide.setPrevInactive();
    currentSlide = slide;
    currentSlide.setPrevActive();
  } else {
    currentSlide.setInactive();
    currentSlide = slide;
    currentSlide.setActive();
  }
}

function createSlide(element, index) {
  let slide = {
    next: null,
    duration: 0,
    index: index,
    element: element,
    isActive: false,
    setActive: setActive,
    setInactive: setInactive,
    setPrevActive: setPrevActive,
    setPrevInactive: setPrevInactive
  };

  if (index === 0) {
    gsap.set(element, { autoAlpha: 1 });
  } else {
    gsap.set(element, { autoAlpha: 0 });
  }

  function setActive() {
    animDirection = 1;
    slide.isActive = true;
    inAnimation = gsap.fromTo(
      element,
      slide.duration,
      { rotationY: -90, rotation:0.01, autoAlpha: 1 },
      { rotationY: 0, rotation:0.01, onComplete: callNext }
    );
  }

  function setPrevActive() {
    animDirection = -1;
    slide.isActive = true;
    inAnimation = gsap.fromTo(
      element,
      slide.duration,
      { rotationY: 90, rotation:0.01, autoAlpha: 1 },
      { rotationY: 0, rotation:0.01, onComplete: callNext }
    );
  }

  function setInactive() {
    slide.isActive = false;
    outAnimation = gsap.to(element, slide.duration, {
      rotationY: 90,
      rotation:0.01,
      onComplete: setAlpha
    });
  }

  function setPrevInactive() {
    slide.isActive = false;
    outAnimation = gsap.to(element, slide.duration, {
      rotationY: -90,
      rotation:0.01, 
      onComplete: setAlpha
    });
  }

  function setAlpha() {
    let $this = this.target;
    gsap.set($this, { autoAlpha: 0 });
  }

  function callNext() {
    if (nextSlide) {
      setSlide(nextSlide);
      nextSlide = null;
    }
  }

  return slide;
}
}, 0);
    }
  }, [data]);
  if(loading) {
    return (
        <div id="loading"><img src="https://media.tenor.com/UnFx-k_lSckAAAAM/amalie-steiness.gif" /></div>
    )
  }
  else if(data) {
 console.log(data);
  return (
    <div id="featuredProjects" className="">
      <div id="container">
      {data.projects.edges
      .filter(({ __typename }) => __typename)
      .map((flexFields, key) => {
      const obj = flexFields;
      for (const key in obj) {
        return (
            <Link key={obj.node.slug} className="slideLink" to={`/projects/${obj.node.slug}`}>
            <div className="slide" data-title={obj.node.title}><div className="absoluteTitle" >{obj.node.title}</div><img className="slideImage" alt={obj.node.featuredImage?.node?.altText} srcSet={obj.node.featuredImage?.node?.srcSet} /></div>
            </Link>
        )
      }
      return null;
    } ) }
        </div>
            <div className="controls">
            <div id="prev">Prev</div>
            <div id="next">Next</div>
            </div>
    </div>
  );
}
}
